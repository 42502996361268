/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React from "react";
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { MortgageGuideRoutes } from "GenericMortgageGuides";
import Footer from "./components/FooterNew/index";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookieSetting } from "cookiesetting-component";

const location = window.location.pathname.toLowerCase();

const baseName = process.env.REACT_APP_BASENAME;

let basePath = baseName;
if (baseName === "/usda-constructionsingleclosing") {
  basePath = basePath + "/";
}

// Create root instance for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {location === baseName ||
      location === "/usda-constructionsingleclosing/" ? (
        <App />
      ) : (
        ""
      )}
      {location !== baseName &&
      location !== "/usda-constructionsingleclosing/" ? (
        <MortgageGuideRoutes
          homePath={basePath}
          showHeader={true}
          showFooter={true}
          FooterComponent={Footer}
        />
      ) : (
        ""
      )}
       <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
